.containerLogin {
  width: 100%;
  min-height: 77.5vh;
}
.containerCardsLogin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.itemConnect {
  flex: 1;
  min-width: 500px;
  margin-bottom: 50px;
}
.itemPC {
  flex: 2.2;
  min-width: 700px;
  margin-bottom: 50px;
}

.login-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.login-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70%;
  width: 70%;
  transform: translate(-50%, -50%);
  background-color: #71add9;
  border: 1px solid #ffffff;
  padding: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  flex-direction: column;
}

.logo_edl {
    margin-bottom: 20px;
}

.close-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
  background-color: #ff7887;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
}

.close-button1 {
  margin-top: 40px;
  background-color: #0a354f;
  color: white;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  font-size: 15px;
  cursor : pointer;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
}

.login-popup-content {
  text-align: center;
  color: #0a354f;
}

@media (max-width : 900px) {
    .container {
        width: 100%;
        min-height: 100%;
      }
      .containerCards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      .itemConnect {
        flex: 1;
        min-width: 200px;
        margin-bottom: 50px;
      }
      .itemPC {
        flex: 2.2;
        min-width: 200px;
        margin-bottom: 50px;
      }
      
      .login-popup-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
      }
      
      .login-popup {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 70%;
        width: 70%;
        transform: translate(-50%, -50%);
        background-color: #71add9;
        border: 1px solid #ccc;
        padding: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        flex-direction: column;
      }
            
      .logo_edl {
          margin-bottom: 20px;
      }
      
      .titlePopUp {
        font-size: 25px;
        color: #5383a5;
        text-transform: uppercase;
      }
      
      .close-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10px;
        right: 10px;
        cursor: pointer;
        font-size: 16px;
        background-color: #ff7887;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
      }
      
      .close-button1 {
        margin-top: 40px;
        background-color: #0a354f;
        color: white;
        border-radius: 10px;
        padding: 10px 20px 10px 20px;
        font-size: 15px;
        cursor : pointer;
        box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
      }
      
      .login-popup-content {
        text-align: center;
        color: #0a354f;
      }

      @media (min-width : 900px) and (max-width : 1200px) {
        .container {
            width: 100%;
            min-height: 100%;
          }
          .containerCards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
          .itemConnect {
            flex: 1;
            min-width: 400px;
            margin-bottom: 50px;
          }
          .itemPC {
            flex: 2.2;
            min-width: 400px;
            margin-bottom: 50px;
          }
          
          .login-popup-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
          }
          
          .login-popup {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            height: 70%;
            width: 70%;
            transform: translate(-50%, -50%);
            background-color: #71add9;
            border: 1px solid #ccc;
            padding: 50px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            flex-direction: column;
          }
                
          .logo_edl {
              margin-bottom: 20px;
          }
          
          .titlePopUp {
            font-size: 25px;
            color: #5383a5;
            text-transform: uppercase;
          }
          
          .close-button {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 10px;
            right: 10px;
            cursor: pointer;
            font-size: 16px;
            background-color: #ff7887;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
          }
          
          .close-button1 {
            margin-top: 40px;
            background-color: #0a354f;
            color: white;
            border-radius: 10px;
            padding: 10px 20px 10px 20px;
            font-size: 15px;
            cursor : pointer;
            box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
          }
          
          .login-popup-content {
            text-align: center;
            color: #0a354f;
          }
        }
}
