.select_custom {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-right: 25px;
    width: 100%;
    max-width: 350px;
    min-width: 200px;
    text-align: center;
    color: #275d83;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../../assets/images/blueArrowFull.png");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position-x: 97%;
    background-position-y: 15px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    /* margin-right: 2rem; */
    padding: 1rem;
    padding-right: 2rem;
}
