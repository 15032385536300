.modal-dmtg {
    font-family: Roboto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.11);
    z-index: 9999;
}

.modal-main-dmtg {
    position: fixed;
    background: white;
    border-radius: 10px;
    width: 95%;
    overflow-y: scroll;
    overflow-x: scroll;
    min-height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.display-block {
    display: block;
}

.display-none{
    display: none;
}

.blockDonataire{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid #71ADD9;
    border-radius: 3px;
    width: 100%;
    height: auto;
    min-height: 150px;
    margin-bottom: 15px;
}

.donataireTitle{
    min-height: 85%;
    width: auto;
    font-size: 13px;
    color: white;
    background-color: #71ADD9;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 40px;
    font-weight: bold;
}

.blockDonateur{
    flex: 1;
    border: 1px solid rgb(157, 114, 169);
    border-radius: 3px;
    margin: 10px;
    max-width: 45%;
}

.heritierTitle{
    display: flex;
    align-items: center;
    height: 100%;
    width: auto;
    font-size: 13px;
    color: white;
    background-color: #71ADD9;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px; /* réduit la valeur de la propriété padding-top */
    font-weight: bold;
    margin-right: 20px;
}

.blockHeritier1{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #71ADD9;
    border-radius: 3px;
    min-width: 400px;
    width: auto;
    height: auto;
    margin-bottom: 15px;
}

.blockHeritier2{
    max-width: 300px;
    width: 100%;
}

.donateurTitle{
    width: auto;
    text-align: center;
    color: white;
    background-color: rgb(157, 114, 169);
    font-size: 12px;
    padding: 10px;
    font-weight: bold;
}

.tableContainer{
    display: block;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid rgb(227, 227, 227);
    width: 35%;
}

.dmtgResults{
    font-size: 11;
    padding-top: 5;
    padding-bottom: 5;
}