@font-face {
    font-family: "Regular";   /*Can be any text*/
    src: local("Roboto-Regular"),
    url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}


body {
    margin: 0;
    padding: 0;
    background-color: #F6F8FA;
    font-family: "Regular";
}

