.input_field {
    text-align: center;
    color: #275d83;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 7px;
    width: 100%;
    max-width: 350px;
    min-width: 200px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
}

.input_abattement {
    text-align: center;
    color: #275d83;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
    box-shadow: inset rgba(0, 0, 0, 0.35) -1px 2px 7px 0px;
    background-color:rgba(176, 176, 176, 0.426);
    border-radius: 7px;
    width: 100%;
    max-width: 350px;
    min-width: 200px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
}

.input_email{
    font-family: 'Roboto';
    text-align: center;
    color: #275d83;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 7px;
    width: 100%;
    max-width: 350px;
    min-width: 320px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
}

.input_field::-webkit-input-placeholder {
    text-align: center;
    color: #275d83;
}

.input_field:-moz-placeholder {
    text-align: center;
    color: #275d83;
}

.input_abattement::-webkit-input-placeholder {
    text-align: center;
    color: #275d83;
}

.input_field:-moz-placeholder {
    text-align: center;
    color: #275d83;
}

.input_email::-webkit-input-placeholder {
    text-align: center;
    color: #275d83;
}

.input_email:-moz-placeholder {
    text-align: center;
    color: #275d83;
}

.input_field[type=number]::-webkit-inner-spin-button,
.input_field[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}



.label_input {
    color: grey;
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 3px;
}
